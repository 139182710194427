export function observeComponent(BEJSSDKObserver) {
  let observer = void 0;
  let listeners = [];
  let doc = window.document;
  let readySet = [];
  let MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

  BEJSSDKObserver.clean = function () {
    // reset head and body
    document.head.ready = false;
    document.body.ready = false;
    // reset footer
    let footerElements = doc.querySelectorAll('footer, .footer, #footer');
    if (footerElements) {
      for (let j = 0; j < footerElements.length; j++) {
        footerElements[j].ready = false;
      }
    }

    let elements = doc.querySelectorAll('.be-ix-link-block');
    for (let i = 0; i < elements.length; i++) {
      elements[i].ready = false;
      elements[i].innerHTML = "";
    }
    listeners = [];
    readySet = [];
  };

  function checkSelector(selector, fn) {
    let elements = doc.querySelectorAll(selector);
    for (let i = 0, len = elements.length; i < len; i++) {
      let element = elements[i];
      for (let j = 0; j < readySet.length; j++) {
        if (readySet[j] === element.className || readySet[j] === element.id) {
          return;
        }
      }
      if (element.className) {
        readySet.push(element.className);
      }
      if (element.id) {
        readySet.push(element.id);
      }

      if (!element.ready || MutationObserver == null) {
        element.ready = true;
        fn.call(element, element);
      }
    }
  }

  function checkListeners() {
    listeners.forEach(function (listener) {
      return checkSelector(listener.selector, listener.fn);
    });
  }

  function removeListener(selector, fn) {
    let i = listeners.length;
    while (i--) {
      let listener = listeners[i];
      if (listener.selector === selector && listener.fn === fn) {
        listeners.splice(i, 1);
        if (!listeners.length && observer) {
          observer.disconnect();
          observer = null;
        }
      }
    }
  }

  /**
   * Fire event on first js selector
   * @param selector string to watch on
   * @param fn       callback function
   */
  BEJSSDKObserver.jsElementReady = function (selector, fn) {
    if (MutationObserver != null) {
      if (!observer) {
        observer = new MutationObserver(checkListeners);
        observer.observe(doc.documentElement, {
          childList: true,
          subtree: true
        });
      }
      listeners.push({selector: selector, fn: fn});
    } else {
      // <= IE8
      if (!document.addEventListener) {
        document.addEventListener = document.attachEvent;
      }
      document.addEventListener("DOMContentLoaded", function (event) {
        let elements = doc.querySelectorAll(selector);
        for (let i = 0, len = elements.length; i < len; i++) {
          let element = elements[i];
          element.ready = true;
          fn.call(element, element);
        }
      });
    }

    checkSelector(selector, fn);
    return function () {
      return removeListener(selector, fn);
    };
  };

  return BEJSSDKObserver.jsElementReady;
}
